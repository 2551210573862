import React from "react"

import Layout from "../components/layout/layout"
import PlaceHolderName from "../components/common/placeHolderName"
import SEO from "../components/seo"

import DoodleJamesVoor from "../images/doodlejames-voor.png"
import DoodleJamesNa from "../images/doodlejames-na.png"
import BlissVoor from "../images/bliss-voor.png"
import BlissNa from "../images/bliss-na.png"
import MaxVoor from "../images/max-voor.png"
import MaxNa from "../images/max-na.png"
import ZoeNa from "../images/zoe-na.png"
import PipNa from "../images/pip-na.png"
import BickelNa from "../images/bickel-na.png"

import ChivasInBad from "../images/chivas-in-bad.png"

import TandenVoor from "../images/tanden-voor.png"
import TandenNa from "../images/tanden-na.png"

const WerkzaamhedenPage = () => {
  return (
    <Layout>
      <SEO title="Werkzaamheden - Hondentrimsalon Astrid Zandbergen" />
      <>
        <PlaceHolderName
          id="knippen-en-trimmen"
          imageSource={DoodleJamesVoor}
          altText="Doodle James voor"
          title="Doodle James (voor)"
          paragraph="Dit is Doodle James voor de knipbeurt bij mij in de Hondentrimsalon."
        />
        <PlaceHolderName
          imageSource={DoodleJamesNa}
          altText="Doodle James na"
          title="Doodle James (na)"
          paragraph="Doodle James weer helemaal opgefrist na de knipbeurt."
        />
        <PlaceHolderName
          imageSource={BlissVoor}
          altText="Bliss voor"
          title="Bliss (voor)"
          paragraph="Dit is bliss voor de knipbeurt bij mij in de Hondentrimsalon."
        />
        <PlaceHolderName
          imageSource={BlissNa}
          altText="Bliss na"
          title="Blisss (na)"
          paragraph="Bliss weer helemaal opgefrist na de knipbeurt."
        />
        <PlaceHolderName
          imageSource={MaxVoor}
          altText="Max voor"
          title="Max (voor)"
          paragraph="Dit is Max voor de knipbeurt bij mij in de Hondentrimsalon."
        />
        <PlaceHolderName
          imageSource={MaxNa}
          altText="Max na"
          title="Max (na)"
          paragraph="Max weer helemaal opgefrist na de knipbeurt."
        />
        <PlaceHolderName
          imageSource={PipNa}
          altText="Pip na"
          title="Pip(na)"
          paragraph="Pip weer helemaal opgefrist na de knipbeurt."
        />
        <PlaceHolderName
          imageSource={ZoeNa}
          altText="Zoë na"
          title="Zoë (na)"
          paragraph="Dit is Zoë na de knipbeurt bij mij in de Hondentrimsalon."
        />
        <PlaceHolderName
          imageSource={BickelNa}
          altText="Bickel na"
          title="Bickel (na)"
          paragraph="Dit is Bickel na de knipbeurt bij mij in de Hondentrimsalon."
        />
        <PlaceHolderName
          id="wassen"
          imageSource={ChivasInBad}
          altText="Chivas in bad"
          title="Chivas in bad"
          paragraph="Chivas aan het genieten van een heerlijk bad."
        />
        <PlaceHolderName
          id="tanden-reinigen"
          imageSource={TandenVoor}
          altText="Tanden reinigen voor"
          title="Tanden reinigen (voor)"
          paragraph="90% van de honden ouder dan 3 jaar heeft gebitsproblemen. Bij mij in de Hondentrimsalon zorg ik ervoor dat de tanden weer optimaal zijn"
        />
        <PlaceHolderName
          imageSource={TandenNa}
          altText="Tanden reinigen na"
          title="Tanden reinigen (na)"
          paragraph="Tanden weer helemaal opgefrist."
        />
      </>
    </Layout>
  )
}

export default WerkzaamhedenPage

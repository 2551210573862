import React from "react"
import styled from "styled-components"

const PlaceHolderName = props => {
  return (
    <TextBlockImageWrapper id={props.id}>
      <img src={props.imageSource} alt={props.altText} />
      <div>
        <h2>{props.title}</h2>
        <p>{props.paragraph}</p>
      </div>
    </TextBlockImageWrapper>
  )
}

const TextBlockImageWrapper = styled.div`
  background: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.theme.spacing.l};
  text-align: center;
  margin: ${props => props.theme.spacing.xs} auto;

  img {
    max-width: 200px;
    max-height: 200px;
    min-width: 200px;
    min-height: 200px;
    object-fit: cover;
  }
  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    width: ${props => props.theme.container.xl};
    flex-direction: row;
    &:nth-child(even) {
      h2 {
        padding-right: 8rem;
      }
      text-align: right;
      flex-direction: row-reverse;
    }
    &:nth-child(odd) {
      text-align: left;
      p,
      h2 {
        padding-left: 6rem;
      }
      h2 {
        margin-right: 6rem;
      }
    }
  }
  p {
    margin: 0 auto;
    flex-direction: column;
    width: ${props => props.theme.container.xxl};
    font-size: ${props => props.theme.fontSize.m};
    @media (min-width: ${props => props.theme.breakpoint.desktop}) {
      width: ${props => props.theme.container.xl};
      margin: unset;
    }
  }
`

export default PlaceHolderName
